import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './routes'
import store from './store'
import OtpInput from "@bachdgvn/vue-otp-input"
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueToast from 'vue-toast-notification'
//google maps
import * as VueGoogleMaps from 'vue2-google-maps'
import './services/api'
import './services/env'
import 'vue-toast-notification/dist/theme-default.css'
import './plugins/base'
import './assets/scss/main.scss'

import JsonCSV from 'vue-json-csv'
Vue.component('downloadCsv', JsonCSV);
Vue.component("v-otp-input", OtpInput);
Vue.use(VueToast)
Vue.use(VueAxios, axios);
Vue.config.productionTip = false
//Google maps Api key and libraries Required
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDQd8J0opyeP_28Ys0tUflwzCGw4_7ufGU',
    libraries: 'places',
  }
});
store.dispatch('auth/init')

new Vue({
    vuetify,
    store,
    router,
    render: h => h(App)
}).$mount('#app')
