export default {
    path: '/',
    name: 'auth',
    redirect: { name: 'auth.login' },
    component: () => import('@/views/Auth'),
    children: [
      {
        path: 'login',
        name: 'auth.login',
        component: () =>
          import('@/views/Auth/Login/login')
      },
      {
        path: 'logout',
        name: 'auth.logout',
        component: () =>
          import('@/views/Auth/Logout/logout')
      },
      {
        path: 'otp',
        name: 'auth.otp',
        component: () =>
          import('@/views/Auth/OTP/otp')
      },
      {
        path: 'contact',
        name: 'auth.contact',
        component: () =>
          import('@/views/Auth/Contact/contact')
      },
      {
        path: 'forgot-password',
        name: 'auth.forgot-password',
        component: () =>
          import('@/views/Auth/Forgot-Password/ForgotPassword')
      },
    ]
}