import Cookies from 'js-cookie';
import router from '@/routes'

export default {
  namespaced: true,
  state: {
    user: {},
    isAuthenticated: false,
    token: {
      email: null,
      expires: null,
      tokenType: 'Bearer',
      id: null
    },
    network_error: 'It seems you have an internet connection problem.',
  },

  getters: {
    getUser(state) {
      return state.user;
    },
  },

  mutations: {
    setUser(state, user) {
      if (user) {
          // console.log('MUTATION SET USER:', user);
          state.user = user;
          localStorage.setItem('user', JSON.stringify(user));
      }
    },
    setToken(state, token) {
      // console.log('MUTATION SET TOKEN:', token);
      if (token) {
          Cookies.set('AUTH_TOKEN', token);
          state.token = token;
          state.isAuthenticated = true;
      }
    },
    clearToken(state) {
      Cookies.remove('AUTH_TOKEN')
      state.token= {
        email: null,
        expires: null,
        tokenType: 'Bearer',
        id: null
      },
      state.user = {}
      state.isAuthenticated = false;
      localStorage.removeItem('user');
    },
  },

  actions: {
    init({ commit }) {
      const token = Cookies.get('AUTH_TOKEN')
      
      commit('setToken', token)

      let user = JSON.parse(localStorage.getItem('user'));
      // console.log(user, 'parse');

      commit('setUser', user);
    },

    logout({ commit }) {
      commit('clearToken')

      setTimeout(() => {
        location.href = '/logout'
      }, 400)
    },
  }
}
