import Vue from 'vue'
import Router from 'vue-router'
import auth from './auth'
import admin from './admin'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        auth,
        admin,
        {
            path: '/dashboard',
            redirect: { name: 'home' },
            component: () => import('@/layouts/Layout'),
            children: [
                // Components
                {
                    name: 'home',
                    path: 'home',
                    component: () => import('@/views/Dashboard/dashboard'),
                },
                {
                    name: 'Persons',
                    path: 'Persons/persons',
                    component: () => import('@/views/pages/Persons/persons'),
                },

                {
                    name: 'COVID-19 Status',
                    path: 'Covid19-Status/status',
                    component: () => import('@/views/pages/Covid19-Status/status'),
                },

                {
                    name: 'Receivers',
                    path: 'Receivers/receivers',
                    component: () => import('@/views/pages/Receivers/receivers'),
                },

                {
                    name: 'HelpCenter',
                    path: 'Help-Center/HelpCenter',
                    component: () => import('@/views/pages/Help-Center/HelpCenter'),
                },

                {
                    name: 'Tags',
                    path: 'Tags/tags',
                    component: () => import('@/views/pages/Tags/tags'),
                },

                {
                    name: 'Location',
                    path: 'Location/location',
                    component: () => import('@/views/pages/Location/location'),
                },

                {
                    name: 'Dashboard',
                    path: 'dashboard/basic-dashboard',
                    component: () => import('@/views/Dashboard/dashboard'),
                },
                {
                    name: 'Change Password',
                    path: 'Change-Password/ChangePassword',
                    component: () => import('@/views/pages/Change-Password/ChangePassword'),
                },
                {
                    name: 'Wifi Credential',
                    path: 'Wifi/wifi',
                    component: () => import('@/views/pages/Wifi/wifi'),
                }
            ]
        },

    ],
})

