import Vue from 'vue'

const env = {
  apiUrl: 'http://13.215.10.248/:8000/api/v1',
  title: 'Hotel Management System',
  address: 'Bukidnon, Philippines'
}

Vue.prototype.$env = env

export default env
