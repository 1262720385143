export default {
    path: '/admin',
    name: 'admin',
    redirect: { name: 'admin.dashboard' },
    component: () => import('@/views/Auth'),
    children: [
        {
            name: 'admin.dashboard',
            path: 'admin',
            component: () => import('@/views/Admin/super_admin'),
        },
    ]
}